import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Sidan kunde inte hittas" />
    <h1>404: Sidan kunde inte hittas</h1>
    <p>
      Sidan finns tyvärr inte. Den har antingen blivit borttagen eller kan det
      bero på en felstavad länk.{" "}
      <Link to="/" class="underlined">
        <h3>Gå tillbaka till startsidan</h3>
      </Link>
    </p>
  </Layout>
)

export default NotFoundPage
